













































import { SGM } from "@/api"
import { FormHelper, Toast } from "@/mixins"
import { UserModule } from "@/store/modules"
import { Component, Mixins } from "vue-property-decorator"
import vSelect from 'vue-select'

@Component({
  components: {
    'v-select': vSelect
  }
})
export default class TransferCharacterModal extends Mixins(FormHelper, Toast) {
  @UserModule.State currentUser

  isOpen = false
  isCreate = false
  targetAccountId = ''
  ticketCategory = {} as { label: string }
  ticketId = ''
  ticketCategories = [
    { label: 'Game' },
    { label: 'Account' },
    { label: 'Discord' },
    { label: 'Launcher' },
    { label: 'Bug Report' },
  ]

  async mounted() {
    this.$root.$on('openTransferCharacterModal', () => {
      this.isOpen = true
      this.isCreate = true
    })
  }

  resetForm() {
  }

  get areFieldsFilled() {
    return this.targetAccountId
        && this.ticketCategory
        && this.ticketId
  }

  async submit() {
    await SGM.transferCharacter({
      gameMasterAccountId: this.currentUser.id,
      playerAccountId: this.targetAccountId,
      motive: `[${this.ticketCategory.label}] - Ticket #${this.ticketId}`
    })

    this.$emit('refreshCharacterTransferTable')

    this.showSuccessToast({
      title: 'Successfully transferred!',
      message: `Accounts has been transferred successfully`
    })
  }
}
